import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast, Toast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { List } from 'vant';
import { Slider } from 'vant';
import 'vant/es/empty/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      checkedGoods: [],
      goods: [],
      address: [],
      address_id: 0,
      beizhu: "",
      select: {},
      show11: 0,
      daili: [],
      t_price: 0,
      t_price_m: 0,
      yun: 0,
      get_data: {}
    };
  },
  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },
    totalPrice() {
      return this.goods.reduce((total, item) => Number(total) + Number(this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0), 0) * 100;
    }
  },
  created: function () {
    this.get_data.type = this.$route.query.type;
    this.get_data.dl_id = this.$route.query.dl_id;
    this.get_data.sku_id = this.$route.query.sku_id;
    this.get_data.num = this.$route.query.num;
    this.onload();
    this.get_imdex_data();
  },
  methods: {
    onload: function (name = false) {
      api.all('/api/order/order_init', {
        type: this.get_data.type,
        dl_id: this.get_data.dl_id,
        sku_id: this.get_data.sku_id,
        num: this.get_data.num
      }, (err, data) => {
        if (!err && data.code === 1) {
          //this.banner = data.data.banner;
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.daili = data.data.daili;
          this.t_price = data.data.t_price;
          this.t_price_m = data.data.t_price_m;
          this.yun = data.data.yun;
        } else {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          showFailToast(data.msg);
        }
      });
    },
    get_imdex_data: function () {
      api.all('/api/user/user_address', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.address = data.data.dizhi;
        } else {
          console.log(data);
        }
      });
    },
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    up: function () {
      let data = this.select;
      data.type = this.show11;
      let _this = this;
      api.all('/api/user/user_address', data, (err, data) => {
        showToast(data.msg);
        _this.get_imdex_data();
      });
    },
    de: function (id) {
      let _this = this;
      api.all('/api/user/de_address', {
        id: id
      }, (err, data) => {
        showToast(data.msg);
        _this.get_imdex_data();
      });
    },
    show_d(type) {
      this.show11 = type;
      if (type < 0) {
        this.select = {
          name: "",
          address: "",
          phone: "",
          code: "",
          is_mo: false
        };
        console.log(1);
      } else {
        this.select = this.address[type];
      }
    },
    to: function (id1) {
      let _this = this;
      _this.$router.push({
        name: id1,
        query: {
          id: 0
        }
      });
    },
    sub: function () {
      if (this.address_id <= 0) {
        showFailToast(this.lang.a5);
        return;
      }
      api.all('/api/order/buy', {
        type: this.get_data.type,
        dl_id: this.get_data.dl_id,
        sku_id: this.get_data.sku_id,
        num: this.get_data.num,
        address_id: this.address_id,
        beizhu: this.beizhu
      }, (err, data) => {
        if (!err) {
          showToast(data.msg);
          if (data.code == 1) {
            this.to('user');
          }
        } else {}
      });
    }
  }
};